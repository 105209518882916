import { ComponentProps } from 'react';
import { StageLogo } from '@stageplus/components/src/stage-logo';
import Link from 'next/link';
import ContainerGrid from 'src/components/container-grid';
import styles from 'src/components/footer.module.css';
import SocialLink from 'src/components/social-link';
import StoreLink from 'src/components/store-link';
import useTranslate from 'src/hooks/use-translate';
import { TranslationKeyCommon } from 'src/types';
type FooterLegalLink = { title: TranslationKeyCommon; href: string | ComponentProps<typeof Link>['href'] };

const legalLinks: FooterLegalLink[] = [
  {
    title: 'footer__privacy',
    href: '/privacy',
  },
  {
    title: 'footer__terms',
    href: '/terms',
  },
  {
    title: 'footer__imprint',
    href: '/imprint',
  },
  {
    title: 'footer__cancel_subscription',
    href: '/account/login?redirect=%2Fsettings%2Fsubscription',
  },
];

const siemensLink = {
  title: 'Siemens Arts Program',
  // link to the partner page of the Siemens Arts Program
  href: '/partner/partner_A1GN4T3ECLP5UDO',
  imageSrc: '/images/sponsor-logo-siemens.svg',
};

const LandingPageFooter = () => {
  const t = useTranslate();
  const currentYear = new Date().getFullYear();

  return (
    <footer className={styles.root} data-test="footer">
      {/* STAGE+ logo + Store Links */}
      <div className="mb-5 mt-12 md:mb-12 md:mt-20">
        <ContainerGrid>
          <div className="col-start-2 col-end-6 md:col-span-4">
            <Link
              href="/"
              prefetch={false}
              className="mx-auto mb-16 block w-full max-w-[310px] rounded-sm outline-offset-2 focus-visible:focus-outline md:mx-0 md:mb-0"
            >
              <StageLogo alt={t('logo__title')} className="size-full select-none md:w-auto" />
            </Link>
          </div>
          <div className="col-start-2 col-end-6 md:col-start-7 md:col-end-13 lg:col-start-8">
            <div className="mx-auto mb-4 flex max-w-44 flex-col items-center gap-4 md:max-w-max md:flex-row md:items-start">
              <StoreLink storeName="google" altText={t('component__store_link_google_title')} />
              <StoreLink storeName="apple" altText={t('component__store_link_apple_title')} />
              <StoreLink storeName="amazon" altText={t('component__store_link_amazon_title')} />
            </div>
          </div>
          <div className="col-span-full md:col-start-7 md:col-end-13 lg:col-start-8">
            <p className="dg-text-regular-4 mb-4 text-center md:text-left">{t('page__footer_apps_disclaimer')}</p>
          </div>
        </ContainerGrid>
      </div>

      {/* divider */}
      <ContainerGrid>
        <div className="col-span-full hidden border-b border-dividerLight md:block" />
      </ContainerGrid>

      {/* social and sponsor links */}
      <div className="mb-12 mt-5 md:my-20">
        <ContainerGrid>
          <div className="col-span-12 mb-16 text-center md:col-span-4 md:mb-0 md:text-left">
            <p className="dg-text-regular-4 mb-4">{t('footer__title_social')}</p>
            <ul data-test="footer-social-links" className="flex w-full justify-center md:justify-start">
              {(['facebook', 'instagram', 'twitter', 'youtube'] as const).map((name) => (
                <li key={name} className="mb-2 mr-2 last:mr-0 lg:mr-3">
                  <SocialLink name={name} />
                </li>
              ))}
            </ul>
          </div>
          <div
            className="col-span-12 col-start-1 text-center md:col-span-4 md:col-start-11 md:text-right"
            data-test="footer-sponsor"
          >
            <p className="dg-text-regular-4 mb-4">{t('footer__title_supported')}</p>
            <p className="dg-text-medium-3">
              <Link href={siemensLink.href} className="rounded-sm outline-offset-2 focus-visible:focus-outline">
                <img
                  src={siemensLink.imageSrc}
                  width="172"
                  height="27"
                  alt={siemensLink.title}
                  className="inline-block select-none"
                />
              </Link>
            </p>
          </div>
        </ContainerGrid>
      </div>

      {/* divider */}
      <ContainerGrid>
        <div className="col-span-full border-b border-dividerLight" />
      </ContainerGrid>

      {/* Privacy Policy, Terms and conditions, and & Imprint links + copyright text */}
      <div className="my-14 lg:my-10">
        <ContainerGrid>
          <div className="col-span-full pb-6 text-center lg:col-span-6 lg:text-left">
            <div
              data-test="footer-links"
              className="flex flex-wrap justify-center lg:flex-nowrap lg:justify-start lg:space-x-6"
            >
              {/* Display a consent manager button */}
              <button
                onClick={() => window.__cmp?.('showScreen')}
                className="dg-text-regular-4 mx-3 mb-3 whitespace-nowrap rounded-sm outline-offset-2 focus-visible:focus-outline lg:mx-0 lg:mb-0"
              >
                {t('footer__privacy_settings')}
              </button>
              {/* Render all legal links */}
              {legalLinks.map(({ href, title }) => (
                <Link
                  href={href}
                  prefetch={false}
                  key={title}
                  className="dg-text-regular-4 mx-3 mb-3 whitespace-nowrap rounded-sm outline-offset-2 focus-visible:focus-outline lg:mx-0 lg:mb-0"
                >
                  {t(title)}
                </Link>
              ))}
            </div>
          </div>
          <div className="col-span-full text-center lg:col-span-6 lg:text-right">
            <p className="dg-text-regular-4 opacity-55" suppressHydrationWarning>
              &copy; Deutsche Grammophon, {currentYear}
            </p>
          </div>
        </ContainerGrid>
      </div>
    </footer>
  );
};

export default LandingPageFooter;
